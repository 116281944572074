import { CSS_BREAKPOINT_USED } from '../../global_styles/_breakpoints';
import { atomCallToActionMarkup, TSTStateCalltoaction } from '../../element_atoms/call-to-action';
import { hexToRgb } from '../../helper_funcs/hexToRgb';
import { generateCardId } from '../../helper_funcs/generateCardId';
import { ETabIndexesOrderingLevels } from '@/types/types';
export type TSTState = any;
const CONFIG_BADGE_MAX_LENGTH: number = 12;
const CONFIG_TITLE_MAX_LENGTH: number = 1000;
let CONFIG_OVERFLOW_REPLACER: string = '...';
const CONFIG_OVERFLOW_REPLACER_LENGTH: number = CONFIG_OVERFLOW_REPLACER.length + 1;
const CONFIG_BUTTON_MAX_LENGTH: number = 'Play More'.length;
CONFIG_OVERFLOW_REPLACER = CONFIG_OVERFLOW_REPLACER_LENGTH >= CONFIG_TITLE_MAX_LENGTH || CONFIG_OVERFLOW_REPLACER_LENGTH >= CONFIG_BADGE_MAX_LENGTH || CONFIG_OVERFLOW_REPLACER_LENGTH >= CONFIG_BUTTON_MAX_LENGTH ? '' : CONFIG_OVERFLOW_REPLACER;
export function renderMarkup(passedState: TSTState = null, window = {}) {
  const state = passedState;
  const id = generateCardId('ark-ui-block-card-hero-marketing');
  /* USED PROPERTIES MAPPING */
  const backgroundImageMobile = state?.background_image_mobile || state?.background_image_desktop || '';
  const backgroundImageDesktop = state?.background_image_desktop || state?.background_image_mobile || '';
  const bgCardGradient: string = (state?.override_color_overlay ? `linear-gradient(180deg,` + hexToRgb(state?.override_color_overlay) + ` , 0.6) 2.56%, ` + hexToRgb(state?.override_color_overlay) + `, 0.2) 50.62%, ` + hexToRgb(state?.override_color_overlay) + `, 0) 100%)` : '') || `linear-gradient(180deg, rgba(0, 0, 0, 0.60) 2.56%, rgba(0, 0, 0, 0.20) 50.62%, rgba(0, 0, 0, 0.00) 100%)`;
  let badgeText = state?.badge || '';
  badgeText = badgeText.length > CONFIG_BADGE_MAX_LENGTH ? `${badgeText.slice(0, CONFIG_BADGE_MAX_LENGTH - CONFIG_OVERFLOW_REPLACER_LENGTH)}${CONFIG_OVERFLOW_REPLACER}` : badgeText;
  let titleText = state?.description?.trim?.() || state?.game_description || '';
  titleText = titleText.length > CONFIG_TITLE_MAX_LENGTH ? `${titleText.slice(0, CONFIG_TITLE_MAX_LENGTH - CONFIG_OVERFLOW_REPLACER_LENGTH)}${CONFIG_OVERFLOW_REPLACER}` : titleText;
  /* MODIFICATIONS COMBOS */
  let btnText = state?.link_label || '';
  btnText = btnText.length > CONFIG_BUTTON_MAX_LENGTH ? `${btnText.slice(0, CONFIG_BUTTON_MAX_LENGTH - CONFIG_OVERFLOW_REPLACER_LENGTH)}${CONFIG_OVERFLOW_REPLACER}` : btnText;
  const btnStateBasic: TSTStateCalltoaction = {
    text: btnText,
    size: 'medium',
    tabIndex: -1,
    override_button_normal_color: state?.override_button_normal_color,
    override_button_normal_background: state?.override_button_normal_background,
    override_button_normal_background_end: state?.override_button_normal_background_end,
    override_button_normal_border: state?.override_button_normal_border,
    override_button_normal_should_use_border: state?.override_button_normal_should_use_border,
    override_button_hover_color: state?.override_button_hover_color,
    override_button_hover_background: state?.override_button_hover_background,
    override_button_hover_background_end: state?.override_button_hover_background_end,
    override_button_hover_border: state?.override_button_hover_border,
    override_button_hover_should_use_border: state?.override_button_hover_should_use_border,
    override_button_active_color: state?.override_button_active_color,
    override_button_active_background: state?.override_button_active_background,
    override_button_active_background_end: state?.override_button_active_background,
    override_button_active_border: state?.override_button_active_border,
    override_button_active_should_use_border: state?.override_button_active_should_use_border,
    override_button_focus_color: state?.override_button_focus_color,
    override_button_focus_background: state?.override_button_focus_background,
    override_button_focus_background_end: state?.override_button_focus_background,
    override_button_focus_border: state?.override_button_focus_border,
    override_button_focus_should_use_border: state?.override_button_focus_should_use_border
  };
  const titleColorOverrideStyle = state?.override_color_description ? ` style="color:${state?.override_color_description};"` : '';
  /* RENDER */
  const renderBackground = () => `
        <picture>
            <source media="(min-width: ${CSS_BREAKPOINT_USED()}px)" srcset="${backgroundImageDesktop}">
            <img
                src="${backgroundImageMobile}"
                alt=${state?.title ? state?.title : 'Background image'}
                loading="lazy"
                style="position: absolute; inset: 0; width: 100%; height: 100%; object-fit: cover; object-position: center;"
            />
        </picture>
    `;
  const renderOverlay = () => `<div class="card_overlay"></div>`;
  const renderBadge = () => `<p class="card_badge text-14"${titleColorOverrideStyle}>${badgeText}</p>`;
  const renderTitle = () => `<p class="card_title"${titleColorOverrideStyle}>${titleText}</p>`;
  const renderButtons = () => `
        ${atomCallToActionMarkup(btnStateBasic)}
    `;
  const dynamicClasses = ``;
  const html = `
       ${generateDynamicStyles(id)}
        <div id="${id}" class="card${dynamicClasses}" tabindex="${ETabIndexesOrderingLevels.NOINTERACTION}">
            ${renderBackground()}
            ${renderOverlay()}
            ${renderBadge()}
            ${renderTitle()}
            ${renderButtons()}
        </div>
    `;
  return html;

  // DYNAMIC STYLES
  function generateDynamicStyles(id: any) {
    return `
            <style>
                #${id} .card_overlay {
                    background: ${bgCardGradient}
                }
            </style>
        `;
  }
}